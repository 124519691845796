import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    color: '#ff0000',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      marginTop: '25%',   
    },
  },
  inputContainer: {
    display: 'none',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
     
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


window.onload = function(){
  let obj = document.getElementById('form-button');
  if(obj != null){
      obj.click();
  }

}

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  let query = useQuery();
  name = (query != null ? query.get("person") : "") ?? "";
  roomName = (query != null ? query.get("roomId") : "") ?? "";
  var roomState = query != null ? query.get("roomState") : "";

  
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>    
      <Typography variant="h6" className={classes.gutterBottom}>
        {((!name || !roomName) && !roomState)
          ? "Video call url is invalide, please try again."
          : ""}
      </Typography>
      <Typography variant="h6" className={classes.gutterBottom}>
        {(roomState)
          ? "Video call is ended, please close the browser."
          : ""}
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-room-name">
              Room Name
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
            />
          </div>
        </div>
        <Grid container justify="flex-end">
          <Button
            id="form-button"
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
}
