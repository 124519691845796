import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import CallEnd from '@material-ui/icons/CallEnd';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();

  const handleEndCall = () => {
    window.history.replaceState(null, '', window.encodeURI(`?roomState=closed`))
    room.disconnect();
    window.location.reload();
  };

  return (
    <Button onClick={handleEndCall} className={clsx(classes.button, props.className)} data-cy-disconnect
      style={{ width: "50px", height: "60px", borderRadius: "50%", padding: "0", margin: "10px" }}
    >
      <CallEnd />
    </Button>
  );
}
