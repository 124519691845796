import React, {useState,  useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ContactPhone from '@material-ui/icons/ContactPhone';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import Menu from './Menu/Menu';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden, TextField, InputLabel } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'transparent',
      bottom: 0,
      left: 0,
      right: 0,
      position: 'fixed',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 10,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();
  const [invitationLink, setInvitationLink] = useState("Invitation Link");
  const [open,setOpen]=useState(false);
  const [copy,setCopy]=useState(false);

  async function fetchData() {
    const urlParams = new URLSearchParams(window.location.search); 
    const roomId = urlParams.get('roomId'); 
    const person = urlParams.get('person');
    const res = await fetch(`https://tcmaapps.com/api/v1/get/video/invite-url?roomId=${roomId}&person=${person}`);
    res
      .json()
      .then(res => setInvitationLink(res?.invitationLink))
      .catch(err => console.log(err));
  }

  useEffect(() => {
    fetchData();
  });

  return (
    <>
        
      {isSharingScreen && (
        <Grid container justify="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justify="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1" style={{ color: "#fff" }}> </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justify="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              <Hidden smDown>{!isSharingScreen && <ToggleScreenShareButton disabled={isReconnecting} />}</Hidden>
              <Button onClick={() => setOpen(true)} style={{ width: "50px", height: "60px", borderRadius: "50%", padding: "0", margin: "10px", background: "#fff" }}
              >
                <ContactPhone />
              </Button>
              <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
                <DialogTitle>Invitation Link</DialogTitle>
                <Divider />
                <DialogContent>
                  <InputLabel shrink htmlFor="invite">
                    Invitation Link
                  </InputLabel>
                  <p>{invitationLink}</p>
                  <Button onClick={() => {
                      navigator.clipboard.writeText(invitationLink)
                      setCopy(true)
                      setTimeout(() => { setCopy(false) }, 2000)
                    }
                  } color="primary" variant="contained" autoFocus>
                    Copy
                  </Button>
                  <p>{ copy ? "Copied to Clipboard" : "" }</p>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary" variant="contained">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              <EndCallButton />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justify="flex-end">
                <Menu />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
