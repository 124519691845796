import React, { useState } from 'react';
import clsx from 'clsx';

import { Button } from '@material-ui/core';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import PersonAdd from '@material-ui/icons/PersonAdd';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';

import Participant from '../Participant/Participant';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '2em',
      overflowY: 'auto',
      background: 'rgb(79, 83, 85)',
      gridArea: '1 / 2 / 1 / 3',
      zIndex: 5,
      [theme.breakpoints.down('sm')]: {
        height: "fit-content",
        gridArea: '1 / 1 / 3 / 3',
        overflowY: 'initial',
        overflowX: 'auto',
        display: 'flex',
        padding: '2em',
      },
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
  })
);

export default function ParticipantList() {
  const [show,setShow]=useState(true);
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <aside
      className={clsx(classes.container, {
        [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
      })}
      style={{ paddingTop: "1px !important" }}
    > 
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Button
          onClick={ () => setShow(!show)} 
          style={{ width: "50px", height: "60px", margin: "10px", padding: "0", borderRadius: "50%", background: "#fff" }}
        >
          <PeopleAlt /> <sup>{ participants.length }</sup>
        </Button>
      </div>
      <div className={classes.scrollContainer} style={{ display: show ? "" : "none" }}>
        <Participant 
          participant={localParticipant} 
          isLocalParticipant={true}
          isSelected={localParticipant === selectedParticipant}
          onClick={() => setSelectedParticipant(localParticipant)} 
        />
        {participants.map(participant => {
          const isSelected = participant === selectedParticipant;
          const hideParticipant =
            participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
          return (
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={participant === selectedParticipant}
              onClick={() => setSelectedParticipant(participant)}
              hideParticipant={hideParticipant}
            />
          );
        })}
      </div>
    </aside>
  );
}
